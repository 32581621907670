<template>
  <SelectForm v-model="accountID" :label="label" class="showPlaceholder" name="accountNumber">
    <el-option
      v-for="item in rebatesList"
      :key="item.login"
      :value="item.login"
      :label="setLabel(item)"
      :data-testid="item.login"
    >
    </el-option>
  </SelectForm>
</template>

<script>
import { apiQueryRebatesList } from '@/resource';

export default {
  name: 'accountNumber',
  props: { label: String },
  data() {
    return {
      accountID: null,
      rebatesList: []
    };
  },
  watch: {
    accountID(accountID) {
      this.updateAccount();
    }
  },
  mounted() {
    this.queryRebatesList();
  },
  methods: {
    updateAccount() {
      const foundAccount = this.rebatesList.find(f => f.login === this.accountID);
      this.$store.commit('behavior/setAID', foundAccount ? this.accountID : '');
      this.$store.commit('behavior/setCUR', foundAccount ? foundAccount.currency : '');
      this.$store.commit('behavior/setBalance', foundAccount ? foundAccount.commission : 0);
      this.$store.commit('behavior/setDataSourceId', foundAccount ? foundAccount.dataSourceId : null);
      this.$emit('accountCallback');
    },
    queryRebatesList() {
      return apiQueryRebatesList().then(resp => {
        if (resp.data.code === 0) {
          this.rebatesList = resp.data.data.list;
          console.log(this.rebatesList);
          this.accountID = this.rebatesList[0] ? this.rebatesList[0].login : null;

          if (!this.accountID) this.updateAccount();
        }
      }, this.$handleError);
    },
    setLabel(item) {
      return item.login + ' - ' + this.$config.accountTypeMaps[item.mtAccountType];
    }
  }
};
</script>
