<template>
  <el-button v-on:click="callbackPromise()" :disabled="loading" data-testid="loading-button">
    <i class="el-icon-loading" v-show="loading"></i>
    {{ $t(text) }}
  </el-button>
</template>

<script>
export default {
  props: ['text', 'callback'],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    callbackPromise() {
      this.loading = true;
      var promise = this.callback.call();
      promise.then(() => {
        this.loading = false;
      });
    }
  }
};
</script>
